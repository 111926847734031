define('@zestia/ember-select-box/components/select-box/option', ['exports', '@zestia/ember-select-box/templates/components/select-box/option', '@zestia/ember-select-box/mixins/select-box/option/activatable', '@zestia/ember-select-box/mixins/select-box/option/base', '@zestia/ember-select-box/mixins/general/disableable', '@zestia/ember-select-box/mixins/general/indexable', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/general/registerable', '@zestia/ember-select-box/mixins/select-box/option/selectable', '@zestia/ember-select-box/mixins/general/styleable'], function (exports, _option, _activatable, _base, _disableable, _indexable, _nameable, _registerable, _selectable, _styleable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_activatable.default, _base.default, _disableable.default, _indexable.default, _nameable.default, _registerable.default, _selectable.default, _styleable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _option.default,
    classNameSuffix: 'option',
    role: 'option',

    attributeBindings: ['title', 'role', 'isActive:aria-current', 'isDisabled:aria-disabled', 'isSelected:aria-selected', 'isPending:aria-busy'],

    classNameBindings: ['isActive', 'isDisabled', 'isSelected'],

    mouseEnter: function mouseEnter() {
      this.send('activate');
    },
    click: function click() {
      this.send('select');
    }
  }]));
});