define('@zestia/ember-select-box/utils/select-box/test-helpers', ['exports', '@ember/test-helpers'], function (exports, _testHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getNativeMultipleSelectBoxValue = getNativeMultipleSelectBoxValue;
  exports.selectNativeOptionsByValue = selectNativeOptionsByValue;
  exports.selectNativeOptionsByLabel = selectNativeOptionsByLabel;
  var from = Array.from;
  function getNativeMultipleSelectBoxValue(selector) {
    return from((0, _testHelpers.find)(selector).options).reduce(function (values, option) {
      if (option.selected) {
        values.push(option.value);
      }

      return values;
    }, []);
  }

  function selectNativeOptionsByValue(selector, values) {
    from((0, _testHelpers.find)(selector).options).forEach(function (option) {
      option.selected = values.includes(option.value);
    });

    return (0, _testHelpers.triggerEvent)(selector, 'change');
  }

  function selectNativeOptionsByLabel(selector, labels) {
    from((0, _testHelpers.find)(selector).options).forEach(function (option) {
      option.selected = labels.includes(option.textContent.trim());
    });

    return (0, _testHelpers.triggerEvent)(selector, 'change');
  }
});