define('@zestia/ember-select-box/mixins/select-box/select-active-option-on-enter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    pressEnter: function pressEnter() {
      this._super.apply(this, arguments);
      this.send('selectActiveOption');
    }
  });
});