define('@zestia/ember-select-box/utils/select-box/class-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = className;
  function className(prefix, suffix) {
    var name = prefix || 'select-box';
    var array = [name];

    if (suffix) {
      array.push(suffix);
    }

    return array.join('-');
  }
});