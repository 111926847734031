define("@zestia/ember-select-box/utils/select-box/scroll-into-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = scrollIntoView;
  function _getOffset(element) {
    var rect = element.getBoundingClientRect();
    var win = element.ownerDocument.defaultView;

    return {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    };
  }

  function _getScrollParent(element) {
    if (!element) {
      return;
    }

    if (element.scrollHeight > element.clientHeight) {
      return element;
    } else {
      return _getScrollParent(element.parentNode);
    }
  }

  /**
   * - Scrolls the element into view
   * - Only supports Up and Down currently.
   * - Left and Right todo.
   */
  function scrollIntoView(element) {
    var scroller = _getScrollParent(element);

    if (!scroller) {
      return;
    }

    var scroll = scroller.scrollTop;
    var elementTop = _getOffset(element).top;
    var scrollerTop = _getOffset(scroller).top;
    var elementBottom = elementTop + element.offsetHeight;
    var scrollerBottom = scrollerTop + scroller.clientHeight;
    var outOfBoundsTop = elementTop - scrollerTop < 0;
    var outOfBoundsBottom = elementBottom > scrollerBottom;

    if (outOfBoundsTop) {
      scroller.scrollTop = scroll + (elementTop - scrollerTop);
    } else if (outOfBoundsBottom) {
      scroller.scrollTop = scroll + (elementBottom - scrollerBottom);
    }
  }
});