define('@zestia/ember-select-box/mixins/select-box/click-outside-event', ['exports', '@zestia/ember-select-box/mixins/general/click-outside', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _clickOutside, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_clickOutside.default, {
    clickOutside: function clickOutside(e) {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, 'on-click-outside', e, this.api);
    }
  });
});