define('@zestia/ember-select-box/mixins/select-box/activatable-options', ['exports', '@zestia/ember-select-box/utils/select-box/scroll-into-view'], function (exports, _scrollIntoView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var fromCharCode = String.fromCharCode;

  var alphaNumeric = /^[0-9a-z]$/i;

  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._deactivateOptions();
    },
    _activateOptionAtIndex: function _activateOptionAtIndex(index, scroll) {
      var under = index < 0;
      var over = index > this.options.length - 1;

      if (!(under || over)) {
        this.set('activeOptionIndex', index);
        this._activatedOption();
      }

      if (scroll) {
        this._scrollActiveOptionIntoView();
      }
    },
    _activateOptionForChar: function _activateOptionForChar(char, scroll) {
      Ember.run.cancel(this.activateOptionCharTimer);

      var timer = Ember.run.later(this, '_resetActivateOptionChars', 1000);
      var index = this.activateOptionCharIndex || 0;
      var lastChars = this.activateOptionChars || '';
      var lastChar = lastChars.substring(lastChars.length - 1);
      var chars = lastChars + char;

      var options = void 0;
      var option = void 0;

      if (lastChar && lastChar === char) {
        options = this._findOptionsMatchingChars(char);
        option = options[index];
      } else {
        options = this._findOptionsMatchingChars(chars);
        option = options[0];
      }

      this.set('activateOptionChars', chars);
      this.set('activateOptionCharTimer', timer);
      this.set('activateOptionCharIndex', index >= options.length - 1 ? 0 : index + 1);

      if (option) {
        this.send('activateOptionAtIndex', Ember.get(option, 'index'), scroll);
      }
    },
    _findOptionsMatchingChars: function _findOptionsMatchingChars(chars) {
      var pattern = new RegExp('^' + chars, 'i');

      return this.options.filter(function (option) {
        return pattern.test(option.element.textContent.trim());
      });
    },
    _resetActivateOptionChars: function _resetActivateOptionChars() {
      this.set('activateOptionChars', '');
    },
    _activatedOption: function _activatedOption() {
      var activeOption = Ember.get(this, 'activeOption');

      if (activeOption) {
        activeOption.send('_activated');
      }
    },
    _deactivateOptions: function _deactivateOptions() {
      this.set('activeOptionIndex', -1);
    },
    _scrollActiveOptionIntoView: function _scrollActiveOptionIntoView() {
      (0, _scrollIntoView.default)(Ember.get(this, 'activeOption').element);
    },


    activeOption: Ember.computed('activeOptionIndex', 'options', function () {
      return this.options.objectAt(this.activeOptionIndex);
    }),

    actions: {
      activateOptionAtIndex: function activateOptionAtIndex(index) {
        var scroll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        this._activateOptionAtIndex(index, scroll);
      },
      activateNextOption: function activateNextOption() {
        var scroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var next = this.activeOptionIndex + 1;
        this._activateOptionAtIndex(next, scroll);
      },
      activatePreviousOption: function activatePreviousOption() {
        var scroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var prev = this.activeOptionIndex - 1;
        this._activateOptionAtIndex(prev, scroll);
      },
      activateOptionForKeyCode: function activateOptionForKeyCode(keyCode) {
        var scroll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var char = fromCharCode(keyCode);

        if (alphaNumeric.test(char)) {
          this._activateOptionForChar(char, scroll);
        }
      },
      deactivateOptions: function deactivateOptions() {
        this._deactivateOptions();
      }
    }
  });
});