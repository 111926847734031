define('@zestia/ember-select-box/mixins/select-box/searchable', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isSearchable: Ember.computed(function () {
      return typeof this['on-search'] === 'function';
    }),

    searchDelayTime: Ember.computed(function () {
      return Ember.getWithDefault(this, 'search-delay-time', 100);
    }),

    searchSlowTime: Ember.computed(function () {
      return Ember.getWithDefault(this, 'search-slow-time', 500);
    }),

    searchMinChars: Ember.computed(function () {
      return Ember.getWithDefault(this, 'search-min-chars', 1);
    }),

    queryOK: function queryOK(query) {
      return query.length >= Ember.get(this, 'searchMinChars');
    },
    _maybeSearch: function _maybeSearch(text) {
      if (Ember.get(this, 'isSearchable')) {
        this._runDebouncedSearch(text);
      }
    },
    _runDebouncedSearch: function _runDebouncedSearch(query) {
      var delay = Ember.get(this, 'searchDelayTime');
      var immediate = !delay;
      Ember.run.debounce(this, '_runSearch', query, delay, immediate);
    },
    _runSearch: function _runSearch(query) {
      query = ('' + query).trim();

      if (!this.queryOK(query) || this.isDestroyed) {
        return;
      }

      this._search(query);
    },
    _search: function _search() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      this.set('isSearching', true);
      this.incrementProperty('searchID');

      Ember.run.debounce(this, '_checkSlowSearch', Ember.get(this, 'searchSlowTime'));

      var search = (0, _invokeAction.default)(this, 'on-search', query, this.api);

      return Ember.RSVP.resolve(search).then(Ember.run.bind(this, '_searchCompleted', this.searchID, query)).catch(Ember.run.bind(this, '_searchFailed', query)).finally(Ember.run.bind(this, '_searchFinished'));
    },
    _searchCompleted: function _searchCompleted(id, query, result) {
      if (id < this.searchID || this.isDestroyed) {
        return;
      }

      (0, _invokeAction.default)(this, 'on-searched', result, query, this.api);
    },
    _searchFailed: function _searchFailed(query, error) {
      if (this.isDestroyed) {
        return;
      }

      (0, _invokeAction.default)(this, 'on-search-error', error, query, this.api);
    },
    _searchFinished: function _searchFinished() {
      if (this.isDestroyed) {
        return;
      }

      this.set('isSearching', false);
      this.set('isSlowSearch', false);
    },
    _checkSlowSearch: function _checkSlowSearch() {
      if (this.isDestroyed) {
        return;
      }

      this.set('isSlowSearch', this.isSearching);
    },


    actions: {
      search: function search(query) {
        return this._search(query);
      },
      stopSearching: function stopSearching() {
        this.incrementProperty('searchID');
        this._searchFinished();
      },
      _inputText: function _inputText(text) {
        this._super.apply(this, arguments);
        this._maybeSearch(text);
      }
    }
  });
});