define('@zestia/ember-select-box/mixins/general/nameable', ['exports', '@zestia/ember-select-box/utils/select-box/class-name'], function (exports, _className) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var from = Array.from;
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      var prefix = this['class-prefix'] || this.classNamePrefix;
      var suffix = this.classNameSuffix;
      var name = (0, _className.default)(prefix, suffix);
      var names = from(this.classNames);

      names.push(name);

      this.set('classNames', names);
    }
  });
});