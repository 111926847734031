define('@zestia/ember-select-box/mixins/select-box/activatable-selected-options', ['exports', '@zestia/ember-select-box/utils/select-box/scroll-into-view'], function (exports, _scrollIntoView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._deactivateSelectedOptions();
    },
    _activateSelectedOptionAtIndex: function _activateSelectedOptionAtIndex(index, scroll) {
      var under = index < 0;
      var over = index > this.selectedOptions.length - 1;

      if (!(under || over)) {
        this.set('activeSelectedOptionIndex', index);
      }

      if (scroll) {
        this._scrollActiveSelectedOptionIntoView();
      }
    },
    _deactivateSelectedOptions: function _deactivateSelectedOptions() {
      this.set('activeSelectedOptionIndex', -1);
    },
    _scrollActiveSelectedOptionIntoView: function _scrollActiveSelectedOptionIntoView() {
      (0, _scrollIntoView.default)(Ember.get(this, 'activeSelectedOption').element);
    },


    activeSelectedOption: Ember.computed('activeSelectedOptionIndex', 'selectedOptions', function () {
      var index = this.activeSelectedOptionIndex;
      return this.selectedOptions.objectAt(index);
    }),

    actions: {
      activateSelectedOptionAtIndex: function activateSelectedOptionAtIndex(index) {
        var scroll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        this._activateSelectedOptionAtIndex(index, scroll);
      },
      activateNextSelectedOption: function activateNextSelectedOption() {
        var scroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var next = this.activeSelectedOptionIndex + 1;
        this._activateSelectedOptionAtIndex(next, scroll);
      },
      activatePreviousSelectedOption: function activatePreviousSelectedOption() {
        var scroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var prev = this.activeSelectedOptionIndex - 1;
        this._activateSelectedOptionAtIndex(prev, scroll);
      },
      deactivateSelectedOptions: function deactivateSelectedOptions() {
        this._deactivateSelectedOptions();
      }
    }
  });
});