define('@zestia/ember-select-box/mixins/select-box/api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var seal = Object.seal;
  exports.default = Ember.Mixin.create({
    init: function init() {
      this.set('api', this._buildApi());
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, '_updateApiElement');
    },
    _buildApi: function _buildApi() {
      return seal(Ember.assign({
        value: undefined,
        element: undefined,
        isOpen: undefined
      }, this._apiActions()));
    },
    _updateApiElement: function _updateApiElement() {
      this._updateApi('element', this.element);
    },
    _updateApi: function _updateApi(key, value) {
      if (this.isDestroyed) {
        return;
      }

      this.set('api.' + key, value);
    },
    _apiActions: function _apiActions() {
      var _this = this;

      return ['open', 'close', 'toggle', 'select', 'update', 'selectActiveOption', 'search', 'stopSearching', 'setInputValue', 'focusInput', 'blurInput', 'activateOptionAtIndex', 'activateNextOption', 'activatePreviousOption', 'activateOptionForKeyCode', 'deactivateOptions', 'activateSelectedOptionAtIndex', 'activateNextSelectedOption', 'activatePreviousSelectedOption', 'deactivateSelectedOptions'].reduce(function (actions, name) {
        actions[name] = Ember.run.bind(_this, Ember.get(_this, 'actions.' + name));
        return actions;
      }, {});
    },


    actions: {
      _updated: function _updated() {
        this._updateApi('value', this.internalValue);
        this._super.apply(this, arguments);
      },
      open: function open() {
        this._super.apply(this, arguments);
        this._updateApi('isOpen', true);
      },
      close: function close() {
        this._super.apply(this, arguments);
        this._updateApi('isOpen', false);
      }
    }
  });
});