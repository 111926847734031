define('@zestia/ember-select-box/components/select-box/native', ['exports', '@zestia/ember-select-box/templates/components/select-box/native', '@zestia/ember-select-box/mixins/select-box/base', '@zestia/ember-select-box/mixins/select-box/focusable', '@zestia/ember-select-box/mixins/select-box/registration/has-options', '@zestia/ember-select-box/mixins/general/nameable'], function (exports, _native, _base, _focusable, _hasOptions, _nameable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var from = Array.from;


  var mixins = [_base.default, _focusable.default, _hasOptions.default, _nameable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _native.default,
    tagName: 'select',

    attributeBindings: ['name', 'title', 'tabindex', 'disabled', 'size', 'multiple', 'autofocus', 'required', 'aria-label'],

    change: function change() {
      var registeredSelected = this._getRegisteredSelectedValues();
      var unregisteredSelected = this._getUnregisteredSelectedValues();

      var selectedValues = void 0;

      if (registeredSelected.length > 0) {
        selectedValues = registeredSelected;
      } else {
        selectedValues = unregisteredSelected;
      }

      if (Ember.get(this, 'isMultiple')) {
        this.send('select', selectedValues);
      } else {
        this.send('select', selectedValues[0]);
      }
    },
    _getRegisteredSelectedValues: function _getRegisteredSelectedValues() {
      return this.options.filter(function (option) {
        return option.element.selected;
      }).map(function (option) {
        return option.internalValue;
      });
    },
    _getUnregisteredSelectedValues: function _getUnregisteredSelectedValues() {
      return from(this.element.querySelectorAll('option:checked')).map(function (option) {
        return option.value;
      });
    }
  }]));
});