define('@zestia/ember-select-box/mixins/select-box/base', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var freeze = Object.freeze;
  exports.default = Ember.Mixin.create({
    isMultiple: Ember.computed.readOnly('multiple'),

    init: function init() {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, 'on-init', this.api);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._update(this.value);
    },
    _select: function _select(value) {
      var _this = this;

      this._update(value).then(function () {
        (0, _invokeAction.default)(_this, 'on-select', _this.internalValue, _this.api);
      });
    },
    _update: function _update(value) {
      if (value === this.internalValue && this.valueID > 0) {
        return Ember.RSVP.resolve(value);
      }

      var id = this.incrementProperty('valueID');

      this.set('internalValue', value);

      return this._resolveValue(value).then(Ember.run.bind(this, '_resolvedValue', id, false)).catch(Ember.run.bind(this, '_resolvedValue', id, true));
    },
    _updated: function _updated() {
      (0, _invokeAction.default)(this, 'on-update', this.internalValue, this.api);
    },
    _resolveValue: function _resolveValue(value) {
      var _this2 = this;

      this.set('isPending', true);
      this.set('isRejected', false);
      this.set('isFulfilled', false);
      this.set('isSettled', false);

      return Ember.RSVP.resolve(value).then(function (value) {
        if (Ember.get(_this2, 'isMultiple')) {
          return Ember.RSVP.all(Ember.makeArray(value));
        }

        return value;
      });
    },
    _resolvedValue: function _resolvedValue(id, failed, value) {
      if (id < this.valueID || this.isDestroyed) {
        return;
      }

      if (Ember.get(this, 'isMultiple')) {
        value = freeze(value);
      }

      if (failed) {
        this.set('isRejected', true);
      } else {
        this.set('isFulfilled', true);
      }

      this.set('isPending', false);
      this.set('isSettled', true);
      this.set('internalValue', value);

      Ember.run.scheduleOnce('afterRender', this, '_rendered');
    },
    _rendered: function _rendered() {
      if (this.isDestroying) {
        return;
      }

      this.send('_updated');
    },


    actions: {
      update: function update(value) {
        this._update(value);
      },
      select: function select(value) {
        this._select(value);
      },
      _updated: function _updated() {
        this._updated();
      },
      _select: function _select(value) {
        this._select(value);
      }
    }
  });
});