define('@zestia/ember-select-box/mixins/select-box/disableable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.disabled)) {
        this.set('isDisabled', this.disabled);
      }

      if (this.isDisabled) {
        this.set('tabIndex', -1);
      }
    }
  });
});