define('@zestia/ember-select-box/mixins/select-box/keyboard-events', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.keys = undefined;
  var keys = exports.keys = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    27: 'escape',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down'
  };

  exports.default = Ember.Mixin.create({
    keyDown: function keyDown() {
      this._super.apply(this, arguments);
      this._keyPressAction.apply(this, arguments);
      this._keyPressMethod.apply(this, arguments);
    },
    _keyPressAction: function _keyPressAction(e) {
      (0, _invokeAction.default)(this, 'on-press-key', e, this.api);

      var key = keys[e.keyCode];

      if (!key) {
        return;
      }

      (0, _invokeAction.default)(this, 'on-press-' + key, e, this.api);
    },
    _keyPressMethod: function _keyPressMethod(e) {
      var key = Ember.String.capitalize(keys[e.keyCode] || '');

      if (!key) {
        return;
      }

      var method = this['press' + key];

      if (typeof method !== 'function') {
        return;
      }

      method.apply(this, arguments);
    }
  });
});