define('@zestia/ember-select-box/mixins/select-box/registration/has-options-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      _registerOptionsContainer: function _registerOptionsContainer(container) {
        (false && !(!this.optionsContainer) && Ember.assert('A select box can only have 1 options container', !this.optionsContainer));

        this.set('optionsContainer', container);
      },
      _deregisterOptionsContainer: function _deregisterOptionsContainer() {
        this.set('optionsContainer', null);
      }
    }
  });
});