define('@zestia/ember-select-box/mixins/select-box/inputtable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _configureAsCombobox: function _configureAsCombobox() {
      if (this.isDestroyed) {
        return;
      }

      this.set('tabIndex', -1);
      this.set('role', 'combobox');
    },


    actions: {
      setInputValue: function setInputValue(value) {
        this.set('input.element.value', value);
      },
      focusInput: function focusInput() {
        this.input.element.focus();
      },
      blurInput: function blurInput() {
        this.input.element.blur();
      },
      _inputText: function _inputText() {},
      _registerInput: function _registerInput() {
        this._super.apply(this, arguments);
        Ember.run.scheduleOnce('afterRender', this, '_configureAsCombobox');
      }
    }
  });
});