define('@zestia/ember-select-box/mixins/select-box/focusable', ['exports', '@zestia/ember-select-box/utils/try-set', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _trySet, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['tabIndex:tabindex'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.tabindex)) {
        this.set('tabIndex', this.tabindex);
      } else if (!Ember.isPresent(this.tabIndex)) {
        this.set('tabIndex', 0);
      }
    },
    focusIn: function focusIn(e) {
      this._super.apply(this, arguments);

      this.set('isFocused', true);

      (0, _invokeAction.default)(this, 'on-focus-in', e, this.api);
    },
    focusOut: function focusOut(e) {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.isClosing) {
        Ember.run.next(function () {
          return (0, _trySet.default)(_this, 'isFocused', false);
        });
      } else {
        this.set('isFocused', false);
      }

      (0, _invokeAction.default)(this, 'on-focus-out', e, this.api);
    }
  });
});