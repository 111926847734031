define('@zestia/ember-select-box/components/select-box/native/group', ['exports', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/templates/components/select-box/native/group'], function (exports, _nameable, _group) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_nameable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _group.default,
    classNameSuffix: 'group',
    tagName: 'optgroup',
    attributeBindings: ['label', 'disabled']
  }]));
});