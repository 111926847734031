define('@zestia/ember-select-box/mixins/general/styleable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Mixin.create({
    attributeBindings: ['customCSS:style'],

    customCSS: Ember.computed('style', function () {
      var css = this.style;

      if (css) {
        return Ember.String.htmlSafe(escapeExpression(css));
      }
    })
  });
});