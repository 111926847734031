define('@zestia/ember-select-box/mixins/select-box/option/activatable', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isActive: Ember.computed('index', '-parent-active-index', function () {
      return Ember.get(this, 'index') === this['-parent-active-index'];
    }),

    actions: {
      activate: function activate() {
        this._super.apply(this, arguments);
        (0, _invokeAction.default)(this, '-on-activate', Ember.get(this, 'index'));
      },
      _activated: function _activated() {
        (0, _invokeAction.default)(this, 'on-activate', this.internalValue, this['-parent-api']);
      }
    }
  });
});