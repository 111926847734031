define('@zestia/ember-select-box/mixins/select-box/toggleable', ['exports', '@zestia/ember-select-box/utils/try-set', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _trySet, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isOpen: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this['is-open'])) {
        this.set('isOpen', this['is-open']);
      }
    },


    actions: {
      open: function open() {
        this._super.apply(this, arguments);

        this.set('isOpen', true);

        (0, _invokeAction.default)(this, 'on-open', this.api);
      },
      close: function close() {
        var _this = this;

        this._super.apply(this, arguments);

        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isClosing', true);
        });
        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isOpen', false);
        });
        Ember.run(function () {
          return (0, _trySet.default)(_this, 'isClosing', false);
        });

        (0, _invokeAction.default)(this, 'on-close', this.api);
      },
      toggle: function toggle() {
        this._super.apply(this, arguments);

        if (this.isOpen) {
          this.send('close');
        } else {
          this.send('open');
        }
      }
    }
  });
});