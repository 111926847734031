define('@zestia/ember-select-box/components/select-box/selected-option', ['exports', '@zestia/ember-select-box/templates/components/select-box/option', '@zestia/ember-select-box/mixins/select-box/option/activatable', '@zestia/ember-select-box/mixins/select-box/option/base', '@zestia/ember-select-box/mixins/general/indexable', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/general/registerable', '@zestia/ember-select-box/mixins/general/styleable'], function (exports, _option, _activatable, _base, _indexable, _nameable, _registerable, _styleable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_activatable.default, _base.default, _indexable.default, _nameable.default, _registerable.default, _styleable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _option.default,
    classNameSuffix: 'selected-option',
    attributeBindings: ['title', 'isActive:aria-current'],
    classNameBindings: ['isActive']
  }]));
});