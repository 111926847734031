define('@zestia/ember-select-box/components/select-box/input', ['exports', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/general/registerable', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _nameable, _registerable, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_nameable.default, _registerable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    tagName: 'input',
    type: '',

    role: 'searchbox',

    attributeBindings: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'autosave', 'dir', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'max', 'maxlength', 'min', 'minlength', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'role', 'selectionDirection', 'size', 'spellcheck', 'step', 'tabindex', 'title', 'type', 'value', 'width'],

    classNameSuffix: 'input',

    input: function input() {
      this._super.apply(this, arguments);

      var value = this.element.value;

      if (!value) {
        (0, _invokeAction.default)(this, 'on-clear', this['-parent-api']);
      }

      (0, _invokeAction.default)(this, '-on-input', value);
      (0, _invokeAction.default)(this, 'on-input', value, this['-parent-api']);
    },
    keyDown: function keyDown(e) {
      this._super.apply(this, arguments);

      if (e.keyCode === 8 && !this.element.value) {
        (0, _invokeAction.default)(this, 'on-delete', this['-parent-api']);
      }
    }
  }]));
});