define('@zestia/ember-select-box/mixins/select-box/option/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._update(this.value);
    },
    _update: function _update(value) {
      if (value === this.internalValue) {
        return;
      }

      var id = this.incrementProperty('valueID');

      this.set('internalValue', value);

      this._resolveValue(value).then(Ember.run.bind(this, '_resolvedValue', id, false)).catch(Ember.run.bind(this, '_resolvedValue', id, true));
    },
    _resolveValue: function _resolveValue(value) {
      this.set('isPending', true);
      this.set('isRejected', false);
      this.set('isFulfilled', false);
      this.set('isSettled', false);

      return Ember.RSVP.resolve(value);
    },
    _resolvedValue: function _resolvedValue(id, failed, value) {
      if (id < this.valueID || this.isDestroyed) {
        return;
      }

      if (failed) {
        this.set('isRejected', true);
      } else {
        this.set('isFulfilled', true);
      }

      this.set('isPending', false);
      this.set('isSettled', true);
      this.set('internalValue', value);
    }
  });
});