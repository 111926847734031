define('@zestia/ember-select-box/components/select-box/options', ['exports', '@zestia/ember-select-box/templates/components/select-box/options', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/general/styleable', '@zestia/ember-select-box/mixins/general/registerable'], function (exports, _options, _nameable, _styleable, _registerable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_nameable.default, _styleable.default, _registerable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _options.default,
    classNameSuffix: 'options',
    attributeBindings: ['role', 'aria-activedescendant'],
    role: 'listbox'
  }]));
});