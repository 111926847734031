define('@zestia/ember-select-box/mixins/general/indexable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    index: Ember.computed('-parent-components', function () {
      return (this['-parent-components'] || []).indexOf(this);
    })
  });
});