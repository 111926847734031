define('@zestia/ember-select-box/mixins/general/document-click', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    clickDocument: function clickDocument() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('_documentClickHandler', Ember.run.bind(this, '_clickDocument'));
      document.addEventListener('click', this._documentClickHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('click', this._documentClickHandler);
    },
    _clickDocument: function _clickDocument() {
      if (this.isDestroyed) {
        return;
      }

      this.clickDocument.apply(this, arguments);
    }
  });
});