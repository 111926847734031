define('@zestia/ember-select-box/mixins/select-box/registration/has-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      _registerInput: function _registerInput(input) {
        if (this.input) {
          (false && !(!this.input) && Ember.assert('select-box can only have 1 input', !this.input));
        }

        this.set('input', input);
      },
      _deregisterInput: function _deregisterInput() {
        this.set('input', null);
      }
    }
  });
});