define('@zestia/ember-select-box/components/select-box/native/option', ['exports', '@zestia/ember-select-box/templates/components/select-box/option', '@zestia/ember-select-box/mixins/select-box/option/base', '@zestia/ember-select-box/mixins/general/indexable', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/general/registerable', '@zestia/ember-select-box/mixins/select-box/option/selectable'], function (exports, _option, _base, _indexable, _nameable, _registerable, _selectable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_base.default, _indexable.default, _nameable.default, _registerable.default, _selectable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _option.default,
    tagName: 'option',

    attributeBindings: ['isSelected:selected', 'internalValue:value', 'disabled', 'title'],

    classNameSuffix: 'option'
  }]));
});