define('@zestia/ember-select-box/mixins/general/registerable', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, '-on-init', this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _invokeAction.default)(this, '-on-destroy', this);
    }
  });
});