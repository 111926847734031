define('@zestia/ember-select-box/mixins/select-box/build-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Array.isArray,
      from = Array.from;
  exports.default = Ember.Mixin.create({
    _buildSelectedValue: function _buildSelectedValue(value1, value2) {
      var build = this['on-build-selection'];

      if (typeof build !== 'function') {
        build = Ember.run.bind(this, '_buildSelectedValueDefault');
      }

      return build(value1, value2);
    },
    _buildSelectedValueDefault: function _buildSelectedValueDefault(value1, value2) {
      var value = value1;

      if (Ember.get(this, 'isMultiple') && !isArray(value1)) {
        var temp = Ember.A(from(value2));

        if (temp.includes(value1)) {
          temp.removeObject(value1);
        } else {
          temp.addObject(value1);
        }

        value = temp.toArray();
      }

      return value;
    },


    actions: {
      _select: function _select(value) {
        value = this._buildSelectedValue(value, this.internalValue);
        this._super(value);
      }
    }
  });
});