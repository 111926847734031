define('@zestia/ember-select-box/mixins/select-box/option/selectable', ['exports', '@zestia/ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember;

  var isSelectedKeys = ['selected', 'internalValue', '-parent-is-multiple', '-parent-internal-value'];

  exports.default = Ember.Mixin.create({
    isSelected: (_Ember = Ember).computed.apply(_Ember, isSelectedKeys.concat([function () {
      if (this.selected !== undefined) {
        return this.selected;
      } else if (this['-parent-is-multiple']) {
        return Ember.makeArray(this['-parent-internal-value']).includes(this.internalValue);
      } else {
        return this.internalValue === this['-parent-internal-value'];
      }
    }])),

    actions: {
      select: function select() {
        this._super.apply(this, arguments);

        if (this.isDisabled) {
          return;
        }

        (0, _invokeAction.default)(this, '-on-select', this.internalValue);
        (0, _invokeAction.default)(this, 'on-select', this.internalValue, this['-parent-api']);
      }
    }
  });
});