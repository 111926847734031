define('@zestia/ember-select-box/mixins/general/click-outside', ['exports', '@zestia/ember-select-box/mixins/general/document-click'], function (exports, _documentClick) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_documentClick.default, {
    clickDocument: function clickDocument(e) {
      this._super.apply(this, arguments);
      var el = this.element;
      var clickedSelf = el === e.target;
      var clickedInside = el.contains(e.target);
      var clickedOutside = !clickedSelf && !clickedInside;

      if (clickedOutside) {
        this.clickOutside();
      }
    },
    clickOutside: function clickOutside() {}
  });
});