define('@zestia/ember-select-box/components/select-box', ['exports', '@zestia/ember-select-box/mixins/select-box/activatable-options', '@zestia/ember-select-box/mixins/select-box/activatable-selected-options', '@zestia/ember-select-box/mixins/select-box/base', '@zestia/ember-select-box/mixins/select-box/build-selection', '@zestia/ember-select-box/mixins/select-box/click-outside-event', '@zestia/ember-select-box/mixins/select-box/disableable', '@zestia/ember-select-box/mixins/select-box/focusable', '@zestia/ember-select-box/mixins/select-box/registration/has-input', '@zestia/ember-select-box/mixins/select-box/registration/has-options', '@zestia/ember-select-box/mixins/select-box/registration/has-options-container', '@zestia/ember-select-box/mixins/select-box/registration/has-selected-options', '@zestia/ember-select-box/mixins/select-box/registration/has-selected-options-container', '@zestia/ember-select-box/mixins/select-box/inputtable', '@zestia/ember-select-box/mixins/select-box/keyboard-events', '@zestia/ember-select-box/templates/components/select-box', '@zestia/ember-select-box/mixins/general/nameable', '@zestia/ember-select-box/mixins/select-box/searchable', '@zestia/ember-select-box/mixins/select-box/select-active-option', '@zestia/ember-select-box/mixins/select-box/select-active-option-on-enter', '@zestia/ember-select-box/mixins/select-box/api', '@zestia/ember-select-box/mixins/general/styleable', '@zestia/ember-select-box/mixins/select-box/toggleable'], function (exports, _activatableOptions, _activatableSelectedOptions, _base, _buildSelection, _clickOutsideEvent, _disableable, _focusable, _hasInput, _hasOptions, _hasOptionsContainer, _hasSelectedOptions, _hasSelectedOptionsContainer, _inputtable, _keyboardEvents, _selectBox, _nameable, _searchable, _selectActiveOption, _selectActiveOptionOnEnter, _api, _styleable, _toggleable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Component;

  var mixins = [_activatableOptions.default, _activatableSelectedOptions.default, _base.default, _buildSelection.default, _clickOutsideEvent.default, _disableable.default, _focusable.default, _hasInput.default, _hasOptions.default, _hasOptionsContainer.default, _hasSelectedOptions.default, _hasSelectedOptionsContainer.default, _inputtable.default, _keyboardEvents.default, _nameable.default, _searchable.default, _selectActiveOption.default, _selectActiveOptionOnEnter.default, _api.default, _styleable.default, _toggleable.default];

  exports.default = (_Ember$Component = Ember.Component).extend.apply(_Ember$Component, mixins.concat([{
    layout: _selectBox.default,

    attributeBindings: ['aria-label', 'role', 'isOpen:aria-expanded', 'isDisabled:aria-disabled', 'isBusy:aria-busy'],

    classNameBindings: ['isOpen', 'isFocused', 'isDisabled', 'isMultiple', 'isSearching', 'isSlowSearch'],

    isBusy: Ember.computed.or('isPending', 'isSearching')
  }]));
});